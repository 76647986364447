<template>
	<MainAppContainer>
		<section class="sptb">
			<div class="container">
				<DealDetail/>
				<RelatedDeals />
			</div>
		</section>
	</MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import DealDetail from '@/components/deals/DealDetail.vue'
import RelatedDeals from '@/components/deals/RelatedDeals.vue'
export default {
	components: {
		MainAppContainer,
		DealDetail,
		RelatedDeals
	}
}
</script>