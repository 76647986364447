<template>
<div v-if="related_deals.deals.length > 0" class="deal-details">
    <div class="row mt-4">
        <div class="col-xs-12">
            <div class="title-block mb-0">
                <h1 class="text-start mb-0">
                    More Great Deals in <fa icon="location-arrow" /> {{related_deals.city}}, {{related_deals.state}}
                </h1>
            </div>
        </div>
    </div>
    <div class="row flex">
        <div v-for="deals in related_deals.deals" :key="deals.id" class="col-lg-4 col-md-6 col-10">
            <div class="deals-block">
                <div class="deals-list">
                    <div class="thumb">
                        <router-link :to="'/deal/' + deals.slug"><img v-if="deals.banner" :src="deals.banner" alt="" class="img-fluid" /><img v-else src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/deals/default.jpg" alt="" class="img-fluid" /></router-link>
                        <div class="mi-no">854 mi</div>
                        <div class="price-tag"><span class="regular-price">${{deals.total_price}}</span> ${{deals.discounted_price}}</div>
                    </div>
                    <div class="content">
                        <router-link :to="'/deal/' + deals.slug"><h3 class="title">{{deals.title}}</h3></router-link>
                        <p>By {{deals.business.title}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
export default {
    data(){
		return{
            related_deals: {
                deals:[],
                city:'',
                state:''
            },
		}
    },
    created() {
        this.getRelatedDeals()
    },
    methods: {
        getRelatedDeals(){
            let thiss = this
            axios.get('related_deals/'+this.$route.params.slug).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.related_deals = response.data.data
                    console.log(thiss.related_deals)
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        }
    }

}
</script>