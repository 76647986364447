<template>
    <div class="row">
        <div class="col-12 col-md-8">
            <div class="deal-banner-wrapper">
                <img v-if="dealData.banner" :src="dealData.banner" alt="Banner" class="img-fluid rounded" />
                <img v-if="!dealData.banner" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/deals/default.jpg" alt="Banner" class="img-fluid rounded" />
            </div>
            <div class="p-3 bg-light border">
                <h2>{{dealData.title}}</h2>
                <p class="text-break">{{dealData.description}}</p>
                <hr>
                <h2>Services:</h2>
                <ul class="ps-0 list-unstyled">
                    <li v-for="services in dealData.deal_services" :key="services.id"><p class="mb-1"> {{services.service.title}}</p></li>
                </ul>
                <hr>
                <p class="mb-1 fw-bold">
                    Price: <span class="ms-5 me-2 h4">${{dealData.discounted_price}}.00</span>  
                    <strike class="text-danger">${{dealData.total_price}}.00</strike>
                </p>
                <p class="fw-bold">You save: <span class="ms-4">${{dealData.total_price - dealData.discounted_price}}.00</span></p>
                <router-link v-if="dealData.business.title_slug" :to="{name:'BookNow',query:{deal:$route.params.slug},params:{slug:dealData.business.title_slug}}" class="btn default-button mb-3">Check Availability</router-link>
                <p class="text-danger">Offer ends on {{changeDateFormate(dealData.endDate,'MMMM DD, YYYY')}} </p>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="sidebar-right">
                <div class="sidebar-box">
                    <div class="location-map">
                        <div ref="map" style="height:325px"></div>
                        <div class="contact-detail" v-if="dealData.business">
                            <p><fa icon="phone-alt" /> {{phoneFormate(dealData.business.phone,true)}}</p>
                            <p><fa icon="map-marker-alt" /> {{dealData.business.street}}, {{dealData.business.city}}, {{dealData.business.state}} {{dealData.business.zip}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'
    import '@vuepic/vue-datepicker/dist/main.css'
    export default {
        data() {
            return {
                dealData:{
                    business:{}
                }
            }
        },
        created() {
            this.getDealDetail()
        },
        methods:{
            getDealDetail(){
                let thiss = this
                axios.get('deal_detail_by_slug/'+this.$route.params.slug).then(function (response) {
                    if (response.data.status == 'success') {
                        console.log(response.data.data);
                        thiss.dealData = response.data.data
                        thiss.drawMap()
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            drawMap(){
                if (this.dealData.business.lat != 'undefined') {
                    const mapOptions = {
                        center: {lat: parseFloat(this.dealData.business.lat), lng: parseFloat(this.dealData.business.lng)},
                        zoom: 16,
                        zoomControl:true,
                        zoomControlOptions:{
                            position: window.google.maps.ControlPosition.LEFT_TOP
                        },
                        mapTypeControl: false, 
                        fullscreenControl: false, 
                        streetViewControl: false,
                        styles: [
                            {
                                featureType: 'poi',
                                stylers: [{visibility: "off"}]
                            }
                        ]
                    };
                    const map = new window.google.maps.Map(this.$refs["map"], mapOptions);
                    new window.google.maps.Marker({
                        position: {lat: parseFloat(this.dealData.business.lat), lng: parseFloat(this.dealData.business.lng)},
                        map,
                        title: this.dealData.business.address,
                    });
                }
            },
            phoneFormate(str,withCountryCode=false){
                //Filter only numbers from the input
                let cleaned = ('' + str).replace(/\D/g, '');
                let match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                let match2 = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                if (match1) {
                    return '(' + match1[1] + ') ' + match1[2] + '-' + match1[3]
                }else if(match2){
                    if(withCountryCode){
                        let intlCode = (match2[1] ? '+1 ' : '')
                        return [intlCode, '(', match2[2], ') ', match2[3], '-', match2[4]].join('')
                    }else{
                        return ['(', match2[2], ') ', match2[3], '-', match2[4]].join('')
                    }
                }
                return str
            },
            changeDateFormate(date,formate){
                return moment(date).format(formate)
            },
            bookDeal(){
                console.log()
            }
        }
    }
</script>

<style scoped>
    .default-button{
        font-size: 16px;
        padding: 10px 30px
    }
</style>